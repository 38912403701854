import { useState, useEffect } from "react"
import Papers from './paper';
import "./App.css"
import Guide from "./components/guide";
import PrivacyPolicy from "./components/policy"
import Billing from "./components/pricing"
import Blog from "./components/blog"
import ScrollToTop from "./scroll"
import Selectlan from "./select";
import Post from "./components/post";
import { collection, getDocs, query, doc, where, onSnapshot } from 'firebase/firestore';
import { db } from "./firebase";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Search from "./components/search";
import Research from "./components/research";
import { auth, googleProvider } from "./firebase"
import Saved from "./components/saved";
import { useAuthState } from 'react-firebase-hooks/auth';
import { signInWithPopup, signInWithEmailAndPassword, createUserWithEmailAndPassword } from 'firebase/auth';

function App() {

  const [user] = useAuthState(auth);

  const [posts, setPosts] = useState([]);
  const [loadingPosts, setLoadingPosts] = useState(false)
  const userRef = collection(db, "custom_user");
  const [userData, setUserData] = useState()
  const [userName, setUserName] = useState("")
  const [userAge, setUserAge] = useState("")

  const [subscriptionStatus, setSubscriptionStatus] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const signInWithEmail = async (email, password, isSignUp) => {
    try {
      if (isSignUp) {
        await createUserWithEmailAndPassword(auth, email, password);
      } else {
        await signInWithEmailAndPassword(auth, email, password);
      }
    } catch (error) {
      let errorMessage = '';
      switch (error.code) {
        case 'auth/email-already-in-use':
          errorMessage = 'This email is already registered';
          break;
        case 'auth/invalid-email':
          errorMessage = 'Invalid email address format';
          break;
        case 'auth/operation-not-allowed':
          errorMessage = 'Email/password accounts are not enabled';
          break;
        case 'auth/weak-password':
          errorMessage = 'Password should be at least 6 characters';
          break;
        case 'auth/user-disabled':
          errorMessage = 'This account has been disabled';
          break;
        case 'auth/user-not-found':
          errorMessage = 'No account found with this email';
          break;
        case 'auth/wrong-password':
          errorMessage = 'Incorrect password';
          break;
        default:
          errorMessage = 'An error occurred during authentication';
      }
      throw new Error(errorMessage);
    }
  };

  useEffect(() => {
    if (user) {
      const getUserdata = async () => {
        const data = await getDocs(userRef);
        setUserData(data?.docs?.map((doc) => ({ ...doc?.data(), id: doc?.id })));
        if (userData) {
          setUserName(userData[0]?.name)
          setUserAge(userData[0]?.age) 
        }
        else {

        }
      }

      getUserdata();
    }
  }, [])

  useEffect(() => {
    if (user) { 
      const paymentsRef = collection(db, "payments_profile");
      const userPaymentDoc = doc(paymentsRef, user.uid);

      const unsubscribe = onSnapshot(userPaymentDoc, (docSnapshot) => {
        if (docSnapshot.exists()) {
          const paymentData = docSnapshot.data();
          setSubscriptionStatus({
            id: docSnapshot.id,
            ...paymentData,
            isActive: paymentData.active === true &&
              paymentData.subscription?.attributes?.status === 'active'
          });
        } else {
          setSubscriptionStatus({
            isActive: false
          });
        }
        setIsLoading(false);
      }, (error) => {
        console.error("Error fetching subscription:", error);
        setIsLoading(false);
      });

      return () => unsubscribe();
    } else {
      setSubscriptionStatus(null);
      setIsLoading(false);
    }
  }, [user]);
 
  const signIn = async (e) => {
    e.preventDefault();
    try {
      const result = await signInWithPopup(auth, googleProvider);
    } catch (error) {
    }
  };

  const signInDos = async (e) => {
    if (e) e.preventDefault(); 
    try {
      const result = await signInWithPopup(auth, googleProvider);
    } catch (error) {
    }
  };

  const signInTres = async (e) => {
    if (e) e.preventDefault();
    try {
      const result = await signInWithPopup(auth, googleProvider);
    } catch (error) {
    }
  };

  const signInCuatro = async (e) => {
    if (e) e.preventDefault();
    try {
      const result = await signInWithPopup(auth, googleProvider);
    } catch (error) {
    }
  };

  const [selectedLanguage, setSelectedLanguage] = useState('');

  useEffect(() => {
    const savedLanguage = localStorage.getItem('selectedLanguage');
    if (savedLanguage) {
      setSelectedLanguage(savedLanguage);
    } else {
      setShowModal(true);
    }
  }, []);

  const [showModal, setShowModal] = useState(false);

  const handleSelectLanguage = (languageCode) => {
    setSelectedLanguage(languageCode);
    localStorage.setItem('selectedLanguage', languageCode);
    setShowModal(false);
  };

  const [isAuthenticated, setIsAuthenticated] = useState(true);

  const [isOpen, setIsOpen] = useState(false);

  const toggleModal = () => {
    if (!isAuthenticated) {
      setIsOpen(!isOpen);
    }
  };

  const [publications, setPublications] = useState([]);
  const [savedPapers, setSavedPapers] = useState([]);
  const paperRef = collection(db, "s3");
  const [loadingS3, setLoadingS3] = useState(false)
  const [savedStates, setSavedStates] = useState({});


  useEffect(() => {
    if (user) {
      setLoadingS3(true);
      const q = query(paperRef, where("uid", "==", user.uid));
      const unsubscribe = onSnapshot(q, (snapshot) => {
        const savedPapersData = snapshot.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id
        }));
        setSavedPapers(savedPapersData);

        const savedStatesObj = {};
        savedPapersData.forEach(savedPaper => {
          const paperId = savedPaper.url;
          savedStatesObj[paperId] = true;
        });
        setSavedStates(savedStatesObj);
        setLoadingS3(false);
      }, (error) => {
        setLoadingS3(false);
      });

      return () => unsubscribe();
    }
  }, [user]);

  useEffect(() => {
    document.title = "VortiX - AI Search Engine for Scientific Research"
  }, [])


  useEffect(() => {
    (function (c, l, a, r, i, t, y) {
      c[a] = c[a] || function () { (c[a].q = c[a].q || []).push(arguments) };
      t = l.createElement(r); t.async = 1; t.src = "https://www.clarity.ms/tag/" + i;
      y = l.getElementsByTagName(r)[0]; y.parentNode.insertBefore(t, y);
    })(window, document, "clarity", "script", "ksl0ws77dq");
  }, []);

  return (
    <Router>
      <Selectlan handleSelectLanguage={handleSelectLanguage} selectedLanguage={selectedLanguage} setSelectedLanguage={setSelectedLanguage} showModal={showModal} setShowModal={setShowModal} />
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<Papers subscriptionStatus={subscriptionStatus} signInWithEmail={signInWithEmail} signInTres={signInTres} signInDos={signInDos} setSavedStates={setSavedStates} savedStates={savedStates} savedDocs={savedPapers} userName={userName} userAge={userAge} signIn={signIn} user={user} isAuthenticated={isAuthenticated} setIsAuthenticated={setIsAuthenticated} toggleModal={toggleModal} handleSelectLanguage={handleSelectLanguage} selectedLanguage={selectedLanguage} setSelectedLanguage={setSelectedLanguage} showModal={showModal} setShowModal={setShowModal} />} />
        <Route
          path="/billing"
          element={
            <Billing
              signInCuatro={signInCuatro}
              setSubscriptionStatus={setSubscriptionStatus}
              user={user}
              subscriptionStatus={subscriptionStatus}
              isLoading={isLoading}
              selectedLanguage={selectedLanguage}
            />
          }
        />
        <Route path="/blog" element={<Blog posts={posts} selectedLanguage={selectedLanguage} setPosts={setPosts} />} />
        <Route path="/privacy" element={<PrivacyPolicy selectedLanguage={selectedLanguage} />} />
        <Route path="/guide" element={<Guide selectedLanguage={selectedLanguage} />} />
        <Route path="/search" element={<Search signIn={signIn} user={user} isAuthenticated={isAuthenticated} setIsAuthenticated={setIsAuthenticated} toggleModal={toggleModal} selectedLanguage={selectedLanguage} />} />
        <Route path="/research" element={<Research posts={posts} loadingPosts={loadingPosts} user={user} isAuthenticated={isAuthenticated} setIsAuthenticated={setIsAuthenticated} toggleModal={toggleModal} selectedLanguage={selectedLanguage} />} />
        <Route path="/saved" element={<Saved user={user} savedPapers={savedPapers} publications={publications} isAuthenticated={isAuthenticated} setIsAuthenticated={setIsAuthenticated} toggleModal={toggleModal} selectedLanguage={selectedLanguage} />} />
        <Route path="/post/:id" element={<Post posts={posts} isAuthenticated={isAuthenticated} setIsAuthenticated={setIsAuthenticated} toggleModal={toggleModal} selectedLanguage={selectedLanguage} />} />
      </Routes>
    </Router>
  );
} 

export default App;
