import React, { useState } from 'react';
import { BeakerIcon, SparklesIcon, UserGroupIcon, LibraryIcon } from '@heroicons/react/outline';
import { CheckIcon } from '@heroicons/react/solid';
import Header from "./header"
import Footer from "./footer"
import { LoaderIcon } from 'lucide-react';

const Billing = ({ subscriptionStatus, signInCuatro, setSubscriptionStatus, selectedLanguage, user }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const currentPlan = subscriptionStatus?.isActive ? 'premium' : 'free';

    const handleCheckout = async () => {
        if (!user) {
            signInCuatro()
        }
        else {
            setIsLoading(true);
            setError(null);
            try {
                const response = await fetch('https://vortixlol.gaia-ml.com/api/checkout/', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        userId: user.uid,
                        userEmail: user.email,
                        plan: 'premium',
                        price: 10
                    }),
                });

                if (!response.ok) {
                    throw new Error('Failed to create checkout session');
                }

                const data = await response.json();
                window.location.href = data.checkoutUrl;
            } catch (err) {
                console.error('Checkout error:', err);
                setError(selectedLanguage === "es-ES"
                    ? 'Error al procesar el pago. Por favor, inténtelo de nuevo.'
                    : 'Payment processing error. Please try again.');
            } finally {
                setIsLoading(false);
            }
        }
    };

    const plans = [
        {
            name: selectedLanguage === "es-ES" ? "Gratis" : "Free",
            description: selectedLanguage === "es-ES" ? "Para empezar a explorar" : "For getting started with VortiX",
            price: "0 USD",
            icon: BeakerIcon,
            features: [
                '1 chat with research papers',
                '5 deep searches',
                '10 AI-powered searches',
                'Basic support'
            ],
            buttonText: selectedLanguage === "es-ES" ? "Plan Actual" : "Current Plan",
            buttonAction: () => { },
            type: 'free'
        },
        {
            name: selectedLanguage === "es-ES" ? "Premium" : "Premium",
            description: selectedLanguage === "es-ES" ? "Para investigadores dedicados" : "For dedicated researchers",
            price: "10 USD",
            icon: SparklesIcon,
            popular: true,
            features: [
                'Unlimited chats with papers',
                'Unlimited deep searches',
                'Unlimited AI searches',
                'Priority support',
                'Save unlimited papers',
                'Advanced analytics'
            ],
            buttonText: currentPlan === 'premium'
                ? (selectedLanguage === "es-ES" ? "Plan Actual" : "Current Plan")
                : (selectedLanguage === "es-ES" ? "Actualizar" : "Upgrade"),
            buttonAction: () => {
                if (currentPlan !== 'premium') {
                    handleCheckout();
                }
            },
            type: 'premium'
        },
        {
            name: selectedLanguage === "es-ES" ? "Equipos" : "Teams",
            description: selectedLanguage === "es-ES" ? "Para grupos de investigación" : "For research groups",
            price: "Custom",
            icon: UserGroupIcon,
            features: [
                'Everything in Premium',
                'Up to 10 team members',
                'Team analytics dashboard',
                'Centralized billing',
                'Admin controls',
                'Team collaboration tools'
            ],
            buttonText: selectedLanguage === "es-ES" ? "Contactar" : "Contact Sales",
            buttonAction: () => window.location.href = 'mailto:hector.diaz@pucp.edu.pe',
            type: 'teams',
            priceDetail: selectedLanguage === "es-ES" ? "/equipo/mes" : "/team/mo"
        },
        {
            name: selectedLanguage === "es-ES" ? "Empresarial" : "Enterprise",
            description: selectedLanguage === "es-ES" ? "Para universidades y organizaciones" : "For universities & organizations",
            price: "Custom",
            icon: LibraryIcon,
            features: [
                'Custom deployment',
                'Dedicated support',
                'API access',
                'SSO integration',
                'Custom contracts',
                'SLA guarantee'
            ],
            buttonText: selectedLanguage === "es-ES" ? "Contactar" : "Contact Sales",
            buttonAction: () => window.location.href = 'mailto:hector.diaz@pucp.edu.pe',
            type: 'enterprise'
        }
    ];

    return (
        <>
            <Header />
            <div className="py-12 px-4 sm:px-12 lg:px-8 bg-gradient-to-b from-white to-gray-50">
                <div className="max-w-7xl mx-auto">
                    {error && (
                        <div className="mb-4 p-4 bg-red-100 border border-red-400 text-red-700 rounded">
                            {error}
                        </div>
                    )}

                    <div className="text-center">
                        <h2 className="text-3xl font-extrabold text-gray-900 sm:text-4xl">
                            {selectedLanguage === "es-ES" ? "Planes simples y transparentes" : "Simple, transparent pricing"}
                        </h2>
                        <p className="mt-4 text-xl text-gray-600">
                            {selectedLanguage === "es-ES"
                                ? "Elije el plan que mejor se adapte a tus necesidades. Precios mensuales mostrados abajo."
                                : "Choose the plan that best fits your needs. Monthly plans displayed below."}
                        </p>
                    </div>

                    <div className="mt-16 grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-4">
                        {plans.map((plan) => {
                            const isCurrentPlan = plan.type === currentPlan;
                            return (
                                <div key={plan.name}
                                    className={`relative rounded-2xl border ${plan.popular ? 'border-blue-200 shadow-lg' : 'border-gray-200'
                                        } bg-white p-8 shadow-sm flex flex-col`}
                                >
                                    <div className="flex-1">
                                        <div className="flex items-center justify-between">
                                            <h3 className="text-xl font-semibold text-gray-900">{plan.name}</h3>
                                            <plan.icon className="h-6 w-6 text-blue-500" />
                                        </div>
                                        <p className="mt-4 text-gray-500">{plan.description}</p>
                                        <div className="mt-6">
                                            <div className="flex items-baseline">
                                                {typeof plan.price === 'number' ? (
                                                    <>
                                                        <span className="text-4xl font-bold tracking-tight text-gray-900">
                                                            ${plan.price}
                                                        </span>
                                                        <span className="ml-1 text-lg text-gray-500">
                                                            {plan.priceDetail || '/mo'}
                                                        </span>
                                                    </>
                                                ) : (
                                                    <span className="text-4xl font-bold tracking-tight text-gray-900">
                                                        {plan.price}
                                                    </span>
                                                )}
                                            </div>
                                        </div>

                                        <ul className="mt-8 space-y-4">
                                            {plan.features.map((feature, index) => (
                                                <li key={index} className="flex items-center">
                                                    <CheckIcon className="h-5 w-5 text-green-500 flex-shrink-0" />
                                                    <span className="ml-3 text-gray-600">{feature}</span>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>

                                    <button
                                        onClick={plan.buttonAction}
                                        disabled={isLoading || isCurrentPlan}
                                        className={`mt-8 w-full rounded-lg px-4 py-3 text-center font-semibold 
                                            ${isCurrentPlan
                                                ? 'bg-green-100 text-green-700 cursor-default'
                                                : plan.popular
                                                    ? 'bg-blue-600 text-white hover:bg-blue-700 disabled:opacity-50'
                                                    : 'bg-gray-100 text-gray-900 hover:bg-gray-200 disabled:opacity-50'
                                            } flex justify-center items-center`}
                                    >
                                        {isLoading && plan.type === 'premium' ? (
                                            <LoaderIcon className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" />
                                        ) : null}
                                        {plan.buttonText}
                                    </button>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
};

export default Billing;